import React from 'react';
import { Link } from 'gatsby';
import styled from 'react-emotion';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'jquery';

import { updateData } from '../data/contentful/actions';

import Layout from '../components/layout';


class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.state;
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateData();
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    // const products = this.props.content.data.products || [];
    return (
      <Layout>
        <section id="home" className="divider fullscreen bg-lightest">
          <div className="display-table text-center">
            <div className="display-table-cell">
              <div className="container pt-0 pb-0">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2">
                    <h1 className="font-150 text-theme-colored mt-0 mb-0"><i className="fa fa-map-signs text-gray-silver"/>404!</h1>
                    <h2 className="mt-0">Oops! Page Not Found</h2>
                    <p>The page you were looking for could not be found.</p>
                    <a className="btn btn-border btn-gray btn-transparent btn-circled smooth-scroll-to-target" href="#contact">Return Home</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({ state, content: state.contentful });

const mapDispatchToProps = dispatch => ({
  updateData: bindActionCreators(updateData, dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndexPage);
